<template lang="pug">
  v-container.fill-height(fluid)
    v-row.justify-center
      v-col(cols="12" sm="6" md="4" lg="3")
        v-alert(
          v-if="loginError"
          type="error"
          border="left"
          elevation="2"
          dismissable
        ) {{ $t('auth.login-failed') }}
        v-card(width="100%" :loading="loadingLogin || step === 3" :disabled="loadingLogin")
          v-card-title Asset Monitoring &amp; Control
          v-card-subtitle {{ hostname }}
          v-card-text {{ $t('auth.login-failed-explain') }}
          v-list(subheader)
            v-subheader {{ $t('auth.login-using') }}
            v-divider
            v-list-item(link @click="loginEmail()")
              v-list-item-avatar
                v-icon(color="primary") mdi-mail
              v-list-item-content
                v-list-item-title {{ $tc('mail-address', 1) }}
              v-list-item-action
                v-icon mdi-open-in-new
            v-divider
            v-list-item(link @click="loginMicrosoft()")
              v-list-item-avatar
                v-icon(color="primary") mdi-microsoft
              v-list-item-content
                v-list-item-title Microsoft Account
              v-list-item-action
                v-icon mdi-open-in-new
            v-divider
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'Login',

  data() {
    return {
      step: 1,
      username: '',
      password: '',
      loginError: false,
      loadingLogin: false,
    };
  },
  computed: {
    hostname() {
      return window.location.hostname;
    },
  },
  methods: {
    loginEmail() {
      this.login(process.env.VUE_APP_MSAL_LOGIN_AUTHORITY);
    },
    loginMicrosoft() {
      this.login(process.env.VUE_APP_MSAL_LOGIN_AUTHORITY_MICROSOFT);
    },
    async login(authority) {
      this.loadingLogin = true;
      this.$store.dispatch('SET_LOADING_APP', this.$t('auth.logging-in-popup'));
      await this.$auth.signIn(authority);
      if (Vue.prototype.$auth.isAuthenticated) {
        this.$router.push({ name: 'home' });
        this.$store.dispatch('LOAD_DATA');
        this.loginError = false;
      } else {
        this.loginError = true;
      }
      this.$store.dispatch('STOP_LOADING_APP');
      this.loadingLogin = false;
    },
  },
});
</script>
